import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import replayDatafeedState from "../../../../../state/replay/atoms/replay_datafeed_state";
import { backtestingManagerState } from "../../../../../state/backtesting/backtesting_manager_state";
import { lastBarState } from "../../../../../state/replay/atoms/lastBar";
import { currentSessionState } from "../../../../../state/backtesting/atoms/current_session";
import { ordersState } from "../../../../../state/backtesting/atoms/orders";
import { symbolsState } from "../../../../../state/backtesting/atoms/symbols";
import useSize from "../hooks/useSize";
import { useFormik } from "formik";
import BacktestingNotification from "./BacktestingNotifications";
import { StopLoss, TakeProfit } from "../../../../../state/backtesting/models/backtesting_models";

export default function OrderMaker() {
    const replayDatafeed = useRecoilValue(replayDatafeedState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const lastBar = useRecoilValue(lastBarState);
    const currentSession = useRecoilValue(currentSessionState);
    const [orders, setOrders] = useRecoilState(ordersState);
    const symbols = useRecoilValue(symbolsState);
    const [showNotification, setShowNotification] = useState(false);

    const [innerHeight, innerWidth] = useSize();

    const validate = (values: {
        side?: string,
        amount?: number,
        price?: number,
        orderType?: string,
        takeProfit?: number,
        stopLoss?: number,
        api?: string
    }) => {
        const errors: {
            side?: string,
            amount?: string,
            price?: string,
            orderType?: string,
            takeProfit?: string,
            stopLoss?: string,
            api?: string
        } = {};
        if (!values.side) {
            errors.side = "you have to buy or sell"
        }
        if (!values.amount) {
            errors.amount = "you have to select an amount"
        }
        if (!values.price && values.orderType == "limit") {
            errors.price = "you have to select a price for a limit order"
        }
        if (!values.orderType) {
            errors.orderType = "you have to select an order type"
        }
        if (values.side == undefined) {

        }
        if (values.side == undefined) {

        }
        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            orderType: "limit"
        },
        validate,
        onSubmit: async (values) => {
            try {
                // take order
                console.log(values)

                await backtestingManager.takeOrder(
                    setOrders,
                    currentSession!.id,
                    values.side!,
                    values.orderType!,
                    values.price!,
                    values.amount!,
                    lastBar!.time / 1000,
                    currentSession!.pairs[0],
                    [],
                    lastBar?.close,
                    new StopLoss("", 100, 3, Date.now(), Date.now(), false),
                    new TakeProfit("", 100, 3, Date.now(), Date.now(), false),
                    currentSession!.strategyId
                )

                values = {}

                setTimeout(() => {
                    setShowNotification(true);
                }, 200)
            } catch (error) {
                errors.api = "failed to create order"
                return false;
            }

            return true;
        },
    });

    useEffect(() => {
        if (values.orderType == "market") {
            values.price = lastBar?.close;
        }
    }, [lastBar, orders]);

    return (
        <>
            <BacktestingNotification
                show={showNotification}
                setShow={setShowNotification}
                title='Order Successfully Created'
                body={`Successfully ${values.side?.includes("buy") ? "Bought" : "Sold"} ${values.amount} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""} for ${values.price} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}.`}
            />
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col justify-start items-stretch space-y-6  xl:basis-1/6 lg:basis-1/5 md:h-1/5 flex-1 px-6 pt-12' style={{ height: innerHeight }}>
                    <div className='flex flex-row justify-between items-center border-b border-gray-800 pb-6'>
                        <h3 className='text-gray-50'>
                            Take Order
                        </h3>
                    </div>
                    <div className='flex flex-col justify-start items-stretch space-y-6'>
                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-50">
                                Position Size
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    id="amount"
                                    name="amount"
                                    type="number"
                                    step="any"
                                    placeholder="0"
                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""}
                                </div>
                            </div>
                            {
                                errors.amount
                                    ? <div>
                                        <p className="text-red-500">{errors.amount}</p>
                                    </div>
                                    : null
                            }
                        </div>
                        <div>
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-50">
                                Market Price
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <input
                                    id="price"
                                    name="price"
                                    readOnly={values.orderType == "market"}
                                    value={values.orderType == "market" ? (lastBar?.close ?? 0) : values.price}
                                    type="number"
                                    step="any"
                                    placeholder={lastBar?.close.toString() ?? "market price"}
                                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    {symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}
                                </div>
                            </div>
                            {
                                errors.price
                                    ? <div>
                                        <p className="text-red-500">{errors.price}</p>
                                    </div>
                                    : null
                            }
                            <div className="mt-3">
                                <div key="limit" className="flex items-center">
                                    <input
                                        defaultChecked={true}
                                        id="limit"
                                        name="orderType"
                                        type="radio"
                                        value="limit"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            values.orderType = e.target.value;
                                            values.price = lastBar?.close;
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="limit" className="ml-3 block text-sm font-medium leading-6 text-gray-50">
                                        Limit Order
                                    </label>
                                </div>
                                <div key="market" className="flex items-center">
                                    <input
                                        defaultChecked={false}
                                        id="market"
                                        value="market"
                                        name="orderType"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            values.orderType = e.target.value;
                                            values.price = lastBar?.close;
                                            handleChange(e)
                                        }}
                                    />
                                    <label htmlFor="market" className="ml-3 block text-sm font-medium leading-6 text-gray-50">
                                        Market Order
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-row justify-between space-x-2'>
                            <div>
                                <label htmlFor="takeProfit" className="block text-sm font-medium leading-6 text-gray-50">
                                    Profit Target
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        id="takeProfit"
                                        name="takeProfit"
                                        type="number"
                                        step="any"
                                        placeholder="0"
                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />

                                </div>
                            </div>
                            <div>
                                <label htmlFor="stopLoss" className="block text-sm font-medium leading-6 text-gray-50">
                                    Stop Loss
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        id="stopLoss"
                                        name="stopLoss"
                                        type="number"
                                        step="any"
                                        placeholder="0"
                                        className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        errors.api
                            ? <div>
                                <p className="text-red-500">{errors.api}</p>
                            </div>
                            : null
                    }
                    <div className='flex flex-row space-x-2 pt-6 items-center justify-stretch'>
                        <button
                            type="submit"
                            className="flex-1 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                            onClick={(e) => {
                                setShowNotification(false);
                                values.side = "buy_long";
                            }}
                        >
                            Buy
                        </button>


                        <button
                            type="submit"
                            className="flex-1 rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                            onClick={(e) => {
                                setShowNotification(false);
                                values.side = "sell_long";
                            }}
                        >
                            Sell
                        </button>

                    </div>
                    <div>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </form>
        </>
    )
}